// Variables
$main-color: #f47926; // Green
$black: #2c2c2c;
$main-padding: 45px;

// Body
body {
    font-family: "gesta", sans-serif;
    font-weight: 300;
    color: #868686;
    font-size: 16px;
    background-color: #e4e4e4;
}

img {
    height: auto;
}

#videoOverlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    top: 0px;
    left: 0px;
    z-index: 99;

    .close {
        position: absolute;
        width: 48px;
        height: 48px;
        background: url('../img/close.png');
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    iframe {
        position: absolute;
        width: 800px;
        height: 460px;
        border: 0px;
        top: 50%;
        left: 50%;
        margin: -230px 0px 0px -400px;
        background: #000000;
    }
}

// Headings
h1, h2, h3, h4, h5 {
    font-family: "gesta", sans-serif;
    font-weight: 400;
    color: $black;
    margin: 0;
    padding: 0;
}

// Links
a {
    color: $main-color;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
        color: #166e8d;
    }

    &:active {
        text-decoration: none;
        color: #166e8d;
    }

    &:visited {
        text-decoration: none;
        color: #166e8d;
    }
}

p {
    font-family: "gesta", sans-serif;
}

ul {
    font-family: "gesta", sans-serif;
}

// Main content
.main {
    margin-top: 30px;

    &.main2 {
        margin-top: 188px;
    }
}

.text-align-center {
    text-align: center;
}

// Button
.button {
    width: auto;
    padding: 10px 20px;
    background: $main-color;
    border-radius: 2px;
    font-size: 17px;
    color: #fff !important;
    background-image: url('../img/arrow-right-white.svg');
    background-size: 10px 12px;
    background-position: left 15px center;
    background-repeat: no-repeat;
    padding-left: 35px;
    border: none;
    transition: all .3s;
    outline: none;
    outline-style: none;
    margin-right: 5px;
    text-decoration: none;
    display: inline-block;

    &:hover {
        background-position: left 20px center;
    }
}

// Navigation 
.navigation {
    position: relative;
    z-index: 2;
    background-color: #FFF;
    padding-left: 45px;
    padding-top: 20px;
    padding-bottom: 20px;

    &.navigation2 {
        position: fixed;
        left: 50%;
        right: 50%;
        margin-left: -585px;
        top: 0px;
        margin-bottom: 144px;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
        @media(max-width: 1200px) {
            margin-left: -485px;
        }
    }

    // logo
    .logo {
        width: 230px;
        float: left;
        margin-top: 25px;
    }

    // nav
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-top: 50px;
        float: right;

        li {
            display: inline-block;
            position: relative;
            margin-right: 20px;
            cursor: pointer;

            &.active {

                a {
                    color: #166e8d;
                }

                ul {

                    li {

                        a {
                            color: #868686 !important;
                        }

                        &.active {

                            a {
                                color: #166e8d !important;
                            }

                        }

                    }
                }

            }

            ul {
                display: none;
                min-width: 200px;
                margin-top: 28px;
                background: #efefef;
                padding: 15px;

                li {
                    background: none;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    background-image: url("../img/arrow-right.svg");
                    background-size: 5px 5px;
                    background-position: left top 13px;
                    background-repeat: no-repeat;
                    padding-left: 15px;
                    border: 0px;

                    a {
                        color: #868686;
                        text-decoration: none;
                        font-size: 14px;
                    }
                }
            }

            &.has-sub {
                background-image: url('../img/dropdown.svg');
                background-size: 10px 10px;
                background-position: right center;
                background-repeat: no-repeat;
                padding-right: 15px;
            }

            a {
                color: #868686;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                    color: #166e8d;
                    cursor: pointer;
                }
            }
        }
    }

    .active {
        color: $main-color;
        text-decoration: none;
    }

    // Navicon
    .navicon {
        width: 25px;
        height: 22px;
        background-image: url('../img/navicon.svg');
        background-repeat: no-repeat;
        background-size: 90%;
        float: right;
        cursor: pointer;
        display: none;
        z-index: 999;
        opacity: .6;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    // Sub nav
    li ul {
        position: absolute;
        top: 0;
        width: auto;
        z-index: 999;
        padding-top: 24px;
        margin-top: 20px;
        display: none;

        li {
            padding: 10px;
            background-color: #efefef;
            border-bottom: 1px solid rgba(0, 0, 0, .1);
            width: 100%;
        }
    }
}

// Top navigation
.top-nav {
    float: right;
    margin-top: -20px !important;
    margin-right: -15px !important;
    padding-right: 12px;

    li {
        // margin-right: -4px!important;
        padding: 15px 20px;
        background-color: #efefef;
        transition: all .3s;
        display: block;
        float: left;
        margin-right: 0px !important;

        &.active {
            background: rgba(241, 111, 92, 1);
            background: -moz-linear-gradient(left, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            background: -webkit-gradient(left top, right top, color-stop(0%, rgba(241, 111, 92, 1)), color-stop(0%, rgba(246, 136, 34, 1)), color-stop(52%, rgba(243, 112, 39, 1)), color-stop(100%, rgba(243, 112, 39, 1)));
            background: -webkit-linear-gradient(left, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            background: -o-linear-gradient(left, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            background: -ms-linear-gradient(left, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            background: linear-gradient(to right, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f16f5c', endColorstr='#f37027', GradientType=1);

            a {
                color: #FFF !important;
            }
        }

        &.trial {
            background: #1098af; /* Old browsers */
            background: -moz-linear-gradient(left, #1098af 0%, #318b99 0%, #006989 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, #1098af 0%, #318b99 0%, #006989 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, #1098af 0%, #318b99 0%, #006989 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1098af', endColorstr='#006989', GradientType=1); /* IE6-9 */

            a {
                color: #FFF !important;
            }
        }

        &:hover {
            background: rgba(241, 111, 92, 1);
            background: -moz-linear-gradient(left, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            background: -webkit-gradient(left top, right top, color-stop(0%, rgba(241, 111, 92, 1)), color-stop(0%, rgba(246, 136, 34, 1)), color-stop(52%, rgba(243, 112, 39, 1)), color-stop(100%, rgba(243, 112, 39, 1)));
            background: -webkit-linear-gradient(left, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            background: -o-linear-gradient(left, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            background: -ms-linear-gradient(left, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            background: linear-gradient(to right, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f16f5c', endColorstr='#f37027', GradientType=1);
            cursor: pointer;

            a {
                color: #FFF !important;
                text-decoration: none !important;
            }
        }
    }
}

// Header
.header {
    padding: 0;
    position: relative;

    @media(max-width: 992px) {
        margin-top: 40px;
    }

    .header-image {
        width: 100%;
        padding-top: 31px;
        padding-bottom: 31px;
        padding-left: 45px;

        h1 {
            font-size: 25px;
            font-weight: 400;
            color: #FFF;
            line-height: 30px;
        }

        h2 {
            font-weight: 700;
            color: #FFF;
            font-size: 25px;
            line-height: 30px;
        }

        &.header-home {
            background-image: url('../img/header-home.jpg');
            background-position: 0%;
        }
    }

    // Bollen
    .bol {
        background-image: url('../img/bol.png');
        width: 147px;
        height: 110px;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        text-align: center;
        padding-top: 20px;
        font-size: 16px;
        color: $main-color;

        @media screen and (max-width: 1024px) {
            width: 130px;
            height: 100px;
            background-size: 100% 100%;
        }

        span {
            font-weight: 700;
            font-family: "gesta", sans-serif;
            color: $main-color;
            font-size: 40px;
            width: 100%;

            @media screen and (max-width: 1024px) {
                font-size: 28px;
            }
        }

        p {
            margin-top: -5px;
            line-height: 16px;
        }

        &.bol1 {
            right: 370px;

            p {
                color: #868686;
            }

            @media screen and (max-width: 1024px) {
                right: 290px;
            }
        }

        &.bol2 {
            right: 210px;

            p {
                color: #868686;
            }

            @media screen and (max-width: 1024px) {
                right: 150px;
            }
        }

        &.bol3 {
            right: 50px;

            p {
                color: #868686;
            }

            @media screen and (max-width: 1024px) {
                right: 10px;
            }
        }

        .count, .count2 {
            display: none;
        }
    }
}

// Header overlay (sub-menu)
.header-overlay {
    background-color: #efefef;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    padding-left: 425px;
    display: none;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        float: left;
        margin-top: 10px;

        li {
            display: block;
            width: auto;
            padding-top: 5px;
            padding-bottom: 5px;
            background-image: url('../img/arrow-right.svg');
            background-size: 5px 5px;
            background-position: left top 13px;
            background-repeat: no-repeat;
            padding-left: 15px;

            a {
                color: #868686;
                text-decoration: none;
                font-size: 14px;

                &:hover {
                    text-decoration: underline !important;
                    color: $main-color !important;
                }
            }
        }

        &:nth-child(1) {
            margin-right: 13px;
        }

        &:nth-child(2) {
            margin-right: 53px;
        }

        &:nth-child(3) {
            margin-right: 35px;
        }
    }
}

// Intro
.intro {
    padding: 65px;
    background-color: #FFF;

    h2 {
        display: block;
        clear: both;
        font-size: 30px !important;
        padding: 0px !important;
        margin-bottom: 25px;
        color: $black !important;
    }

    .introtext {
        .button {
            padding: 10px 15px;
            margin-top: 30px;
            font-size: 17px;
            display: inline-block;
            background: $main-color;
            color: $black !important;
        }
    }
}

.home-list {

    h2 {
        padding: 0px;
        margin: 0px;
    }

}

.files-gallery {
    img {
        margin-bottom: 30px;
    }
}

.handy-links {
    ul {
        margin-bottom: 0px;
        margin-top: 40px;
    }
}

// Questions

.questions {
    padding-bottom: 30px;
    min-height: 272px;
    padding-left: 150px;
    @media(max-width: 992px) {
        padding-left: 0px;
    }

    &.no-pl {
        padding-left: 0px;
    }

    .question-box {
        background-color: #efefef;
        width: 100%;
        float: right;
        height: auto !important;
        margin-bottom: 20px;
        max-width: 430px;
        height: 222px;
        padding: 40px;

        &.question-box-green {
            background: rgba(241, 111, 92, 1);
            background: -moz-linear-gradient(left, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            background: -webkit-gradient(left top, right top, color-stop(0%, rgba(241, 111, 92, 1)), color-stop(0%, rgba(246, 136, 34, 1)), color-stop(52%, rgba(243, 112, 39, 1)), color-stop(100%, rgba(243, 112, 39, 1)));
            background: -webkit-linear-gradient(left, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            background: -o-linear-gradient(left, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            background: -ms-linear-gradient(left, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            background: linear-gradient(to right, rgba(241, 111, 92, 1) 0%, rgba(246, 136, 34, 1) 0%, rgba(243, 112, 39, 1) 52%, rgba(243, 112, 39, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f16f5c', endColorstr='#f37027', GradientType=1);

            h1 {
                color: #fff;
            }

            p {
                color: #fff;
            }

            .button {
                font-size: 17px;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 35px;
                padding-right: 20px;
                background-color: transparent;
                background-image: url('../img/arrow-right.svg');
                color: $main-color !important;
                background-size: 35px 13px;
                background-position: left center;
                background-repeat: no-repeat;
                background-color: #fff;
                border-radius: 2px;
                color: $black !important;
                font-weight: 500;

                &:hover {
                    background-position: left 5px center;
                }
            }

            h2 {
                color: #fff !important;
            }
        }

        h1 {
            font-size: 26px;
            margin-bottom: 0;
        }

        h2 {
            font-size: 26px !important;
        }

        p {
            margin-top: 15px;
            margin-bottom: 15px;
            color: #5e5e5e;
        }

        .button {
            font-size: 17px;
            padding: 0;
            padding-left: 25px;
            background-color: transparent;
            background-image: url('../img/arrow-right-orange.svg');
            color: $main-color !important;
            background-size: 16px 20px;
            background-position: left center;
            background-repeat: no-repeat;

            &:hover {
                background-position: left 5px center;
            }
        }
    }
}

.home-question-box {
    padding-left: 75px;
    @media(max-width: 992px) {
        padding-left: 0px;
    }
}

.col-homepage {
    .row {
        .block:nth-child(1) {
            .info {
                padding-bottom: 65px;
                @media (max-width: 1200px) {
                    padding-bottom: 55px;
                }
                @media (max-width: 992px) {
                    padding-bottom: 30px;
                }
                @media (max-width: 767px) {
                    padding-bottom: 55px;
                }
            }
        }

        .block:nth-child(2) {
            .info {
                padding-bottom: 90px;
                @media (max-width: 1200px) {
                    padding-bottom: 80px;
                }
                @media (max-width: 767px) {
                    padding-bottom: 55px;
                }
            }
        }

        .block:nth-child(3) {
            .info {
                @media (max-width: 1200px) {
                    padding-bottom: 55px;
                }
                @media (max-width: 767px) {
                    padding-bottom: 55px;
                }
            }
        }
    }
}

// Sidebar
.sidebar-right {

    .top {
        width: 339px;
        height: 30px;
        background-image: url('../img/top.png');
        background-repeat: no-repeat;
        margin-left: auto;
        margin-right: auto;
        background-size: 100%;
    }

    .bottom {
        width: 339px;
        height: 37px;
        background-image: url('../img/bottom.png');
        background-repeat: no-repeat;
        margin-left: auto;
        margin-right: auto;
        background-size: 100%;
    }

    .sidebar-box {
        background-color: #efefef;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        padding: 25px;

        ul {
            list-style: none;
            padding: 0px 0px 0px 10px;
            margin: 10px 0px 0px 0px;

            li {

                a {
                    font-size: 20px;
                    text-decoration: none;
                }

            }
        }

        h1 {
            font-size: 24px;
            margin-bottom: 0;
        }

        h2 {
            font-size: 24px;
            border-bottom: 1px solid rgba(0, 0, 0, .1);
            margin-bottom: 0;
            padding-bottom: 5px;
            margin-top: 15px;
            cursor: pointer;

            + ul {
                display: none;
                list-style: none;
                padding: 0px;

                li {

                    a {
                        color: #868686;
                        text-decoration: none;
                    }
                }
            }

        }

        p {
            margin-top: 0;
            margin-bottom: 0;
        }

        .button {
            font-size: 21px;
            padding: 0;
            padding-left: 30px;
            background-color: transparent;
            background-image: url('../img/arrow_blue.svg');
            color: $main-color;
            background-size: 20px 20px;
            background-position: left center;
            background-repeat: no-repeat;

            &:hover {
                background-position: left 5px center;
            }
        }
    }
}

.language {

    ul {
        padding: 0px;
        margin: 10px 0px 0px 0px;
        list-style: none;

        li {

            padding: 4px 0px 7px 35px;

            &:first-child {
                background: url('../img/nl.jpg') left no-repeat;
            }

            &:last-child {
                background: url('../img/en.jpg') left no-repeat;
            }

            a {
                text-decoration: none;
            }

        }
    }

}

// Publications
.publication {
    margin-top: 30px;
    margin-bottom: 30px;

    h2 {
        font-size: 18px;
    }

    span {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        opacity: .8;
        padding-bottom: 5px;
        width: 100%;
        display: inline-block;
        margin-top: 15px;
    }

    p {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .button {
        font-size: 21px;
        padding: 0;
        padding-left: 30px;
        background-color: transparent;
        background-image: url('../img/arrow-right-orange.svg');
        color: $main-color !important;
        background-size: 20px 20px;
        background-position: left center;
        background-repeat: no-repeat;

        &:hover {
            background-position: left 5px center;
        }
    }
}

// Home list
.home-list {
    padding: 45px;
    background-color: #efefef;

    h1 {
        font-size: 24px;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-top: 25px;

        li {
            background-image: url('../img/check.svg');
            background-size: 25px 25px;
            background-repeat: no-repeat;
            background-position: left top 5px;
            font-size: 16px;
            color: #959595;
            padding-top: 0px;
            padding-bottom: 0px;
            padding-left: 45px;
            line-height: 35px;
        }
    }
}

.intro {

    h1 {
        font-size: 24px;
    }

    h3 {
        // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 18px;
        // padding-bottom: 10px;
    }

    p {
        margin-top: 20px;
        margin-bottom: 30px;
        padding-right: 30px;
        line-height: 25px;
    }

    ul {
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

}

// Blocks
.blocks {
    padding: 45px;
    padding-left: 60px;
    padding-right: 60px;
    background-color: #FFF;
    padding-bottom: 5px;

    .block {
        padding: 0;
        border-right: 1px solid #fff;

        &:hover {
            cursor: pointer;

            .info {
                .button {
                    background-position: left 5px center;
                }
            }
        }

        .info {
            background: url('../img/homepage-block-bg.png');
            padding-left: 25px;
            padding-top: 35px;
            padding-right: 50px;
            padding-bottom: 40px;
            color: #FFF;
            font-size: 17px;
            height: 400px;

            &.last-child {
                padding-bottom: 65px;
            }

            h1 {
                font-size: 26px;
                color: #fff;
            }

            p {
                margin-bottom: 15px;
                margin-top: 15px;
                color: #fff;
                line-height: 25px;
                padding-right: 0px;
                font-family: "gesta", sans-serif;
            }

            .button {
                background-color: transparent;
                padding: 0;
                padding-left: 30px;
                background-size: 15px 15px;
                background-position: left center;
                color: #fff !important;
                display: inline-block;
                margin-top: 10px;
                background-image: url(../img/longarrow-right-white.svg);
                position: absolute;
                bottom: 15px;

                &:hover {
                    background-position: left 5px center;
                }
            }
        }

        .head1 {
            background-image: url('../img/blok1.jpg');
            width: 100%;
            height: 180px;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .head2 {
            background-image: url('../img/blok2.jpg');
            width: 100%;
            height: 180px;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .head3 {
            background-image: url('../img/blok3.jpg');
            width: 100%;
            height: 180px;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
}

.row-faq {

    h2 {
        padding: 0px;
        margin: 20px 0px 10px 0px;
    }

    h3 {
        margin-top: 30px;
    }

    ul {
        margin-bottom: 20px;
    }

}

// Footer
.footer {
    background-color: #efefef;
    color: #959595;
    padding: 45px;
    margin-bottom: 0px;
    @media(min-width: 992px) {
        margin-bottom: 30px;
    }

    h1 {
        font-size: 26px;
        margin-bottom: 25px;
        color: #166e8d;
    }

    ul {
        padding: 0;
        margin: 0;
        margin-top: 20px;
        list-style-type: none;

        .phone {
            background-image: url('../img/phone.svg');
            background-size: 20px 20px;
            background-position: left center;
            background-repeat: no-repeat;
            padding-left: 30px;
            margin-bottom: 5px;
        }

        .mail {
            background-image: url('../img/mail.svg');
            background-size: 20px 20px;
            background-position: left center;
            background-repeat: no-repeat;
            padding-left: 30px;
            margin-bottom: 5px;
        }

        .twitter {
            background-image: url('../img/twitter.svg');
            background-size: 20px 20px;
            background-position: left center;
            background-repeat: no-repeat;
            padding-left: 30px;
            margin-bottom: 5px;

            a {
                color: #868686;
            }
        }
    }
}

// Content container
.content-container {
    padding: 45px;
    background-color: #FFF;

    h1 {
        font-size: 24px;
    }

    p {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

// Boxes
.boxes {

    .box {
        margin-top: 15px;
        margin-bottom: 15px;
        float: left;
        width: 100%;
    }

    .box-head img {
        width: 100%;
        min-height: 186px;
    }

    .box-info {
        background-color: #efefef;
        padding: 20px;

        h2 {
            font-size: 24px;
        }

        p {
            margin-top: 0px;
            margin-bottom: 10px;
        }

        .published {
            opacity: .6;
            margin: 0;
            padding: 0;
        }

        a {
            text-decoration: none;
        }

        a:visited {
            color: $main-color;
        }

        .button {
            font-size: 17px;
            padding: 0;
            padding-left: 30px;
            background-color: transparent;
            background-image: url('../img/arrow-right-orange.svg');
            color: $main-color !important;
            background-size: 20px 20px;
            background-position: left center;
            background-repeat: no-repeat;

            &:hover {
                background-position: left 5px center;
            }
        }

    }
}

// News
.news-section {
    .box-info {
        height: auto;
        @media(min-width: 992px) {
            height: 265px;
        }
        @media(min-width: 1200px) {
            height: 245px;
        }
    }
}

// Subjects
.subject {
    margin-top: 30px;
    margin-bottom: 30px;

    h2 {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        font-size: 18px;
        padding-bottom: 10px;
    }

    p {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.prevPage {
    background: url('../img/arrow-right-orange.svg') 2px 1px no-repeat;
    background-size: 17px 14px;
    padding-left: 25px;
    text-decoration: none;

    &.top {
        float: left;
        margin-bottom: 20px;
    }
}

.feedbackBar {
    font-weight: bold;
    margin-bottom: 20px;
}

.radiobox-patient {
    margin-bottom: 10px;
}

#contactform {

    h3 {
        padding: 20px 0px;
    }

    input[type=radio] {
        margin-right: 10px;
    }

    label {
        font-weight: 100;

        &.oranje-formulier {
            width: calc(100% - 30px);
        }

        &.ic-proms {
            width: calc(100% - 30px);
        }

        &.pre-maligne-benigne {
            width: calc(100% - 30px);
        }
    }

    .ic-proms {
        label {
            width: calc(100% - 30px);
        }
    }

    .pre-maligne-benigne {
        label {
            width: calc(100% - 30px);
        }
    }
}


input {
    background: #efefef;
    border: 1px solid #dbdbdb;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
}

textarea {
    background: #efefef;
    border: 1px solid #dbdbdb;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    height: 150px;
    margin-bottom: 10px;
    resize: none;
}

.errorField {
    border: 1px solid red !important;
}

.team {
    img {
        width: 100%;
    }
}

.videowrap {
    float: left;
    margin: 20px 0px 0px 50px;
}

.video {
    float: left;
    width: 430px;
    height: 240px;
    background: url('../img/video.jpg');
    border: 0px;
    margin-bottom: 10px;
}

.video2 {
    @media(max-width: 992px) {
        float: left;
        width: 430px;
        height: 240px;
        background: url('../img/video2.jpg');
        border: 0px;
        margin-bottom: 10px;
    }
}

// MEDIA QUERIES

// 1200px
@media (max-width: 1200px) {

    #videoOverlay {

        iframe {
            position: absolute;
            width: 600px;
            height: 346px;
            border: 0px;
            top: 50%;
            left: 50%;
            margin: -173px 0px 0px -300px;
            background: #000000;
        }
    }

    // Navigation
    .navigation {
        padding-left: 25px;

        .logo {
            width: 225px;
            margin: 0;
        }

        // Nav
        ul {
            margin-top: 30px;
        }
    }

    // Button
    .button {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    // Intro
    .intro {
        padding: 25px;
    }

    // Header
    .header {

        .header-image {
            padding: 25px;
            padding-top: 35px;

            h1, h2 {
                font-size: 22px;
            }
        }
    }

    // Home list
    .home-list {
        padding: 25px;
    }

    // Blocks
    .blocks {
        padding: 25px;
        padding-left: 40px;
        padding-right: 40px;

        .block {

            .info {

                min-height: 350px;

                h1 {
                    min-height: 60px;
                }
            }

        }
    }

    // Footer
    .footer {
        padding: 25px;
    }

    // Questions
    .questions {

        .question-box {
            margin-left: 10px;
        }
    }

    // Content container
    .content-container {
        padding: 25px;
    }

    // Header overlay
    .header-overlay {
        padding-left: 225px;
    }

    .videowrap {
        float: left;
        margin: 20px 0px 0px 10px;
    }

    .video {
        float: left;
        width: 430px;
        height: 240px;
        background: url('../img/video.jpg');
        border: 0px;
        margin-bottom: 10px;
    }

    .video2 {
        float: left;
        width: 430px;
        height: 240px;
        background: url('../img/video2.jpg');
        border: 0px;
        margin-bottom: 10px;
    }

}

// 992px
@media (max-width: 992px) {
    // Body
    body {
        padding-top: 32px;
    }

    // Navigation
    .navigation {
        width: 100%;
        // height: 100%;
        background-color: #FFF;
        z-index: 99;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, .05);
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        position: fixed;
        top: 0;

        .logo {
            width: 100px;
            margin-top: 13px;
        }

        ul {
            margin-top: 0;
            float: none;
            width: 100%;
            overflow-y: scroll;
            max-height: 80vh;
            z-index: 99;
            display: none;

            &.main-nav {
                height: 100vh;
            }

            li {
                display: block;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 15px;
                padding-right: 15px;
                border-bottom: 1px solid rgba(0, 0, 0, .1);
                margin-right: 0;

                &:last-child {
                    border-bottom: 0;
                }

                &.has-sub {
                    background-position: right 17px;
                }

                ul {
                    margin-top: 0px;
                    background: none;
                }
            }
        }

        // Navicon
        .navicon {
            display: block;
        }

        // Sub nav
        li ul {
            position: relative;
            width: 100%;
            z-index: 999;
            padding-top: 0;
            margin-top: 0;
            display: none;

            li {
                padding: 0;
                padding-top: 10px;
                padding-bottom: 10px;
                background-color: #FFF;
                border-bottom: 1px solid rgba(0, 0, 0, .1);
                width: 100%;
            }
        }
    }

    // Intro
    .intro {
        padding: 15px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .over-organisatie {
        margin-bottom: 40px;
    }

    // Header
    .header {
        .bol {
            display: none;
        }

        .header-image {
            padding: 25px;

            h1 {
                font-size: 25px;
                margin-bottom: 5px;
            }

            h2 {
                font-size: 25px;
            }
        }
    }

    // Home list
    .home-list {
        padding: 15px;
    }

    // Blocks
    .blocks {
        padding: 15px;
        padding-top: 0;
        padding-bottom: 0;

        .block {

            .info {
                padding: 15px;
                min-height: 360px;

                h1 {
                    font-size: 20px;
                    min-height: 30px;
                }
            }
        }
    }

    // Footer
    .footer {
        padding: 15px;
        padding-top: 30px;
        padding-bottom: 30px;

        img {
            margin-bottom: 25px;
        }
    }

    // Questions
    .questions {

        .question-box {
            margin-left: 0px;

            p {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            h1 {
                font-size: 20px;
            }

            .button {
                font-size: 18px;
            }
        }
    }

    // Content container
    .content-container {
        padding: 15px;
        padding-top: 40px;
    }

    // Header overlay
    .header-overlay {
        display: none !important;
    }

    .videowrap {
        float: left;
        margin: 20px 0px 0px 0px;
    }

    .video {
        float: left;
        width: 345px;
        height: 193px;
        background: url('../img/video.jpg');
        background-size: cover;
        border: 0px;
        margin-bottom: 10px;
    }

    .video2 {
        float: left;
        width: 345px;
        height: 193px;
        background: url('../img/video2.jpg');
        background-size: cover;
        border: 0px;
        margin-bottom: 10px;
    }
    .button {
        &.button-contact {
            margin-bottom: 50px;
        }
    }

}

// 768px
@media (max-width: 768px) {

    .col-homepage {

        .button {
            display: block;
        }

    }

    #videoOverlay {

        iframe {
            position: absolute;
            width: 300px;
            height: 173px;
            border: 0px;
            top: 50%;
            left: 50%;
            margin: -86px 0px 0px -150px;
            background: #000000;
        }
    }

    // Main
    .main {
        margin-top: 0;
        margin-bottom: 0;
    }

    // Header
    .header {

        .header-image {
            padding: 15px;

            h1 {
                font-size: 21px;
            }

            h2 {
                font-size: 21px;
            }
        }
    }

    // Home list
    .home-list {
        h1 {
            margin-bottom: 20px;
        }

        h2 {
            padding-bottom: 30px !important;
        }

        ul {
            margin-top: 0;

            li {
                padding-bottom: 10px;
                background-position: left top;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    padding-bottom: 10px;
                }
            }
        }
    }

    // Intro
    .intro {
        h1 {
            margin-bottom: 20px;
        }
    }

    // Questions
    .questions {
        background-image: none;
        padding-right: 0px;

        .question-box {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            height: auto;
            padding-bottom: 30px;

            p {
                margin-bottom: 20px;
            }
        }
    }

}

// 480px
@media (max-width: 480px) {

    .blocks {

        .block {

            .info {
                min-height: 220px;
            }
        }
    }

    .videowrap {
        float: left;
        margin: 20px 0px 0px 0px;
    }

    .video {
        display: block;
        clear: both;
        width: 300px;
        height: 171px;
        background: url('../img/video.jpg');
        background-size: cover;
        border: 0px;
        margin-bottom: 10px;
    }

    .video2 {
        display: block;
        clear: both;
        width: 300px;
        height: 171px;
        background: url('../img/video2.jpg');
        background-size: cover;
        border: 0px;
        margin-bottom: 10px;
    }

}

.form-control:focus {
    box-shadow: none;
}

.input-group.checkbox {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.intro {
    .login-title {
        font-weight: 400;
        color: #2c2c2c;
        font-size: 24px !important;
    }
}

#login-box {
    float: left;
    position: relative;
    background-color: #efefef;
    padding: 30px;
    margin-bottom: 30px;
    z-index: 1;

    .input-group {
        float: left;
        width: 100%;

        label {
            float: left;
            clear: left;
            font-weight: normal;
        }

        input {
            margin-bottom: 10px;
        }
    }
}

.footer-links {
    margin-top: 50px;

    a {
        color: #868686;
    }
}

// Privacy
.container._privacy {

    // Col 6
    .col-sm-6 {
        width: 100% !important;
    }
}

.studies {
    .button {
        font-size: 21px;
        padding: 0;
        padding-left: 30px;
        background-color: transparent;
        background-image: url('../img/arrow-right-orange.svg');
        color: $main-color !important;
        background-size: 20px 20px;
        background-position: left center;
        background-repeat: no-repeat;

        &:hover {
            background-position: left 5px center;
        }
    }
}